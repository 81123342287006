import { css } from '@amedia/brick-tokens';
import '@amedia/brick-pill';

import { Payload } from '../../types/attributes';
import { getHumanReadableDate } from '../../utils';

const metadataWrapper = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '$x3 0 0',
  color: '$baseFg',
  variants: {
    feature: {
      true: {
        justifyContent: 'center',
        margin: '$x6 0 0',
      },
    },
  },
});

const publishedTextStyle = css({
  order: 1,
  color: 'inherit',
  fontstyle: 'baseMetaM',
});

const readEstimateTextStyle = css({
  order: 2,
  marginLeft: 'auto',
  color: 'inherit',
  fontstyle: 'baseMetaM',
  variants: {
    feature: {
      true: {
        marginLeft: '0',
      },
    },
  },
});

const metadata = (data: typeof Payload) => {
  if (data.small) return '';

  const published = getHumanReadableDate(data.published);

  const renderCountdown = () => {
    const countdownDate = new Date(data.countdown);

    return `
    <brick-pill-v7 data-version="countdown" data-countdown-date="${countdownDate.getTime()}" data-countdown-toggle-text="Direkte" data-countdown-toggle-version="breaking"></brick-pill-v7>`;
  };

  const renderPublished = () =>
    data.published && !data.feature
      ? `<span class="${publishedTextStyle()}">${published}</span>`
      : '';

  const renderReadEstimate = () =>
    data.readEstimate
      ? `<span class="${readEstimateTextStyle(data)}">${
          data.readEstimate
        }</span>`
      : '';

  return `
    <p class="${metadataWrapper(data)}">
      ${data.countdown ? renderCountdown() : renderPublished()}
      ${renderReadEstimate()}
    </p>
  `;
};

export default metadata;
